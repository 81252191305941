<template>
  <div class="score-pages">
    <wsHeader ref="header" />
    <div class="score-page-main">
      <ScoreHeader @update="filterUpdate"/>
      <component v-bind:is="currentGame" :filterLeagues="filterLeagues"></component>
    </div>
  </div>
</template>

<script>
import wsHeader from '@/components/wsHeader';
import ScoreHeader from '@/components/score/header';
import Football from '@/views/score/football';
import Basketball from '@/views/score/basketball';

export default {
  name: "score_pages",
  components: {
    wsHeader,
    ScoreHeader,
    Football,
    Basketball
  },
  data () {
    return {
      filterLeagues: []
    }
  },
  computed: {
    currentGame () {
      let component = Football
      switch ((this.$route.params.type || '').toLowerCase()) {
        case 'football':
          component = Football
          break;
        case 'basketball':
          component = Basketball
          break;
        default:
          component = Football
          break;
      }
      return component
    }
  },
  methods: {
    filterUpdate (leagues) {
      this.filterLeagues = leagues
    }
  }
}
</script>

<style lang="less" scope>
.score-pages{
  background:#f7f7f8;
}
.score-page-main {
  width: 1200px;
  margin: 0 auto;
  padding-top: 24px;
}
</style>